import React, { Component } from 'react';
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
         <div className="row">
            <div className="three columns">
               <img className="profile-pic"  src="images/profile-pic.png" alt="" />
            </div>
            <div className="nine columns main-col">

               <h2>About Me</h2>
               <p>
               {
                 resumeData.aboutme
               }
               </p>

               <div className="row">

                  <div className="columns contact-details">
                     <h2>Contact Details</h2>
                     <p className="address">
                        <span>
                           {resumeData.name}
                        </span>
                        <br></br>
                        <a href={`mailto:${resumeData.email}`} target="_top">
                           {resumeData.email}
                        </a>
                        <br></br>
                        <span>
                           {resumeData.languages}
                        </span>
                        <br></br>
                        <span>
                           {resumeData.address}
                        </span>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
    );
  }
}